import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button, Seo, Section, Card, Title } from "@bw/bits"
import { Hero, Columns, ContentWithImage, Video } from "@bw/modules"
import { Newsletter } from "@bw/partials"
import { Layout } from "@bw/layouts"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import BigLogo from "../images/big-logo"
import { Local, Bio, Vrac } from "@bw/icons"
import heroImage from "../images/hero.jpg"

const StyledDiv = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-top: 20px;
`
const IconWrap = styled.div`
  margin: auto;
  padding: 15px;
  width: auto;
  height: 120px;

  svg {
    width: auto;
    height: 100%;
  }
`

const StyledH3 = styled.h3`
  font-family: var(--font-title);
  font-weight: 900;
  font-size: 35px;
  line-height: 36px;
  color: var(--primary);
  margin: 0;
`
const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title="Votre épicerie bio, vrac, local à Gland" />
      <Hero
        suptitle={t("Votre")}
        title={t("épicerie bio")}
        excerpt={
          "Votre épicerie bio, vrac, local à Gland est en construction !  Venez nous rencontrer au Pop-up shop à la rue du Borgeaud 9 à Gland dès le 1er décembre !" +
          " Soutenez-nous en participant à notre crowdfunding sur wemakeit.ch"
        }
        background="var(--primary)"
        backgroundImage={heroImage}
        button={
          <Button
            primary
            label={t("Soutenez-nous sur wemakeit.ch")}
            href="https://wemakeit.com/projects/chez-l-epicier"
            inversed
            style={{ zIndex: "200" }}
          />
        }
        hasImage={<BigLogo />}
      />
      <Section pattern padding="0 0 60px">
        <ContentWithImage
          withLine
          orientation="text_on_right"
          title={
            <Title
              suptitle="Notre"
              title="projet"
              type="h3"
              icon={<Bio size="small" />}
            />
          }
          text={
            <>
              <p>
                Nous voulons créer une épicerie bio de qualité misant sur le
                zéro-déchets et le circuit court en se positionnant en
                adéquation avec les défis écologiques actuels.
                <p></p>
                Chez l’Épicier est un commerce où l’on prend le temps de vivre
                et d’échanger dans une ambiance détendue, mais aussi un magasin
                professionnel et moderne répondant aux besoins actuels de notre
                clientèle.
              </p>
            </>
          }
          image={
            <Card
              type="image"
              pattern="yellow"
              patternBackground="white"
              direction="left"
              imageURL={
                <Video url="https://www.youtube.com/watch?v=cjGKTLaiFAI" />
              }
            />
          }
        />
      </Section>
      <Section
        fillInverse
        padding="160px 0 80px"
        zAxis="front"
        backgroundImage={
          <StaticImage
            src="../images/chevres.jpg"
            alt="Chèvres laitières"
            placeholder="blurred"
          />
        }
      >
        <Columns count={2}>
          <div style={{ color: "#fff" }}>
            <Title
              suptitle="Notre"
              title="vision"
              type="h3"
              inverse
              row
              brush
              icon={<Bio color="#fff" size="small" />}
            />
            <p style={{ margin: "60px 0 0" }}>
              Nous créons un commerce à taille humaine avec un assortiment large
              et un service professionnel et convivial.
            </p>
          </div>
          <div style={{ color: "#fff" }}>
            <p>
              Nous sélectionnons des produits bio avec le meilleur rapport
              qualité - prix - proximité - durabilité.
            </p>
            <p>
              Nous misons sur la collaboration avec des producteurs romands et
              créons des partenariats avec des entreprises locales.
            </p>
          </div>
        </Columns>
      </Section>
      <Section pattern suptitle="Notre" title="mission" justify="center" lines>
        <Columns count={3} padding="30px 0 0 0">
          <StyledDiv>
            <IconWrap>
              <Bio />
            </IconWrap>
            <StyledH3>Bio</StyledH3>
            <p style={{ margin: "10px 0 0" }}>
              Chez l'Épicier, des produits sains !
            </p>
            <p>
              Nous serons spécialisés dans les produits labellisés bio. En
              effet, nous sommes persuadés que la production biologique est
              actuellement la seule garantie pour notre santé, celle de nos sols
              et de nos écosystèmes.
            </p>
          </StyledDiv>
          <StyledDiv>
            <IconWrap>
              <Vrac />
            </IconWrap>
            <StyledH3>Vrac</StyledH3>
            <p style={{ margin: "10px 0 0" }}>Chez l'Épicier, zéro-déchet !</p>
            <p>
              Nous privilégions le vrac afin de limiter les emballages et de
              permettre au client d’acheter uniquement selon ses besoins.
              Lorsque l’emballage est nécessaire, le verre, le papier, le carton
              ou tout autre contenant recyclable est favorisé. La gestion des
              invendus sera prise en compte dans le même état d’esprit.
            </p>
          </StyledDiv>
          <StyledDiv
            style={{ backgroundColor: "#fff", position: "relative", zIndex: 2 }}
          >
            <IconWrap>
              <Local />
            </IconWrap>
            <StyledH3>Local</StyledH3>
            <p style={{ margin: "10px 0 0" }}>
              Chez l'Épicier, des saveurs d’ici !
            </p>
            <p>
              S’approvisionner au plus proche est l’évidence même ! Soutenir nos
              paysans et nos artisans, limiter notre empreinte carbone et
              valoriser une économie locale et résiliente font partie de nos
              buts.
            </p>
          </StyledDiv>
        </Columns>
      </Section>
      <Section grunge="after" padding="80px 0" background="var(--grey)">
        <Columns count={2}>
          <div>
            <Title suptitle="Fondateur" title="Yann" type="h3" />
            <p>
              Originaire et habitant de Givrins dans le district de Nyon, je
              suis marié et j’ai trois enfants.
            </p>
            <p>
              Je suis issu du commerce de détail alimentaire puisque j’ai passé
              22 ans au sein d’une grande enseigne suisse, dont 15 ans à diriger
              des magasins sur l’arc lémanique.
            </p>
            <p>
              J’ai décidé d’ouvrir mon magasin pour proposer à mes clients une
              manière de consommer différente, plus en adéquation avec mes
              valeurs et les défis écologiques actuels : Chez l’Épicier !
            </p>
          </div>
          <div style={{ textAlign: "right" }}>
            <Card
              type="image"
              pattern="yellow"
              direction="left"
              imageURL={
                <StaticImage
                  src="../images/yann_bechtel.jpg"
                  alt="Yann Bechtel"
                  placeholder="blurred"
                  width={500}
                  height={650}
                />
              }
            />
          </div>
        </Columns>
      </Section>
      <Section>
        <Newsletter />
      </Section>
    </Layout>
  )
}

export default IndexPage
